(function($) {
  var $hostEl = $( '[data-syndicate]' ),
    defaults = {
      'endpoint'   : 'https://www.propublica.org/',
      'element'    : '#newsletter-roadblock',
      'stylesheet' : 'https://assets.propublica.org/prod/v3/css/deploy/syndicated-newsletter.css',
      'frequency'  : 3,
      'hostCookie' : 'bigstory'
    },
    iframew = function( iframe ) {
      if ( iframe.contentWindow ) {
        iframew = iframe.contentWindow;
      } else {
        if ( iframe.contentDocument && iframe.contentDocument.document ) {
          iframew = iframe.contentDocument.document;
        } else {
          iframew = iframe.contentDocument;
        }
      }
      return iframew;
    },
    showLogic = function( hostCookie, frequency ) {
      var signupStatus  = cookie( 'pp_newsletter_' + hostCookie + '_subscribed' ),
        displayStatus = cookie( 'pp_newsletter_interstitial_display' ),
        pageCount     = cookie( 'pp_page_count' ),
        recaptchaInit = typeof recaptchaInit === "undefined" ? null :  recaptchaInit;

      if (pageCount == null) {
        pageCount = 0;
        cookie( 'pp_page_count', pageCount, 365);
      }

      if (signupStatus == 'true') {
        frequency = frequency * 5;
      } else if (displayStatus == 'false') {
        frequency = frequency * 3;
      } else if (displayStatus == null) {
        cookie( 'pp_newsletter_interstitial_display', 'true', 365);
      }

      pageCount++;
      cookie( 'pp_page_count', pageCount, 365);

      if (pageCount % frequency == 0 || displayStatus === null ) {
        recaptchaInit && recaptchaInit();
        return true;
      }
    },
    injectStylesheet = function( target, href ){
      var link = document.createElement( 'link' );

      link.setAttribute( 'rel', 'stylesheet' );
      link.setAttribute( 'type', 'text/css' );
      link.setAttribute( 'href', href );

      // Bail if this stylesheet is already `link`ed:
      for( l = target.document.styleSheets.length, i = 0; i < l; i++ ) {
        if( target.document.styleSheets[ i ].href == href ) {
          return;
        }
      }

      target.document.write( link.outerHTML );
    },
    injectScript = function( target, href ){
      var link = document.createElement( 'script' );

      link.setAttribute( 'src', href );

      // Bail if this stylesheet is already `link`ed:
      for( l = target.document.styleSheets.length, i = 0; i < l; i++ ) {
        if( target.document.styleSheets[ i ].href == href ) {
          return;
        }
      }

      target.document.write( link.outerHTML );
    },
    cookie = function( name, value, days ){
      // if value is undefined, get the cookie value
      if( value === undefined ){
        var cookiestring = "; " + window.document.cookie;
        var cookies = cookiestring.split( "; " + name + "=" );
        if ( cookies.length === 2 ){
          return cookies.pop().split( ";" ).shift();
        }
        return null;
      } else {
        var expires;
        var domain;
        // if value is a false boolean, we'll treat that as a delete
        if( value === false ){
          days = -1;
        }
        if ( days ) {
          var date = new Date();
          date.setTime( date.getTime() + ( days * 24 * 60 * 60 * 1000 ) );
          expires = "; expires="+date.toGMTString();
        }
        else {
          expires = "";
        }
        // check if we're in dev, if not, lets make it a domain-wide cookie
        if( location.hostname == 'localhost' || location.hostname == '127.0.0.1' ) {
          domain = "";
        } else {
          domain = "; domain=.propublica.org";
        }
        window.document.cookie = name + "=" + value + expires + domain +  "; path=/";
      }
    },
    handleResult = function( data ) {
      var $hostEl  = $( this ),
        element    = $hostEl.data( 'element' ) || defaults.element,
        frequency  = $hostEl.data( 'frequency' ) || defaults.frequency,
        hostCookie = $hostEl.data( 'cookie' ) || defaults.hostCookie,
        $newsModal = $( data ).filter( element ),
        $injectEl  = $( '<iframe>', {
          class: "syndicated-modal"
        }).css({
          position: 'fixed',
          left : 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 1001,
          width: '100%',
          height: '100%'
        }),
        target = $hostEl.data( 'target' ),
        stylesheet = $hostEl.data( 'stylesheet' ) || defaults.stylesheet,
        iframewin;

      if( target ) {
        $newsModal
          .find( '[name="target"]' )
          .val( target );
      }

      if( showLogic( hostCookie, frequency ) === true  && $newsModal.length > 0 ) {
        $injectEl
          .append( $newsModal )
          .insertAfter( $hostEl );

        $newsModal
          .css({
            opacity: 0 // Hide the contents until the styles have loaded.
          })
          .addClass( 'collapsible-enhanced collapsible-expanded' )
          .removeClass( 'collapsible-collapsed' );

        iframewin = iframew( $injectEl[ 0 ] );

        iframewin.document.open();
        iframewin.document.write( $newsModal[ 0 ].outerHTML );

        injectStylesheet( iframewin, stylesheet );

        injectScript( iframewin, "https://www.google.com/recaptcha/api.js?onload=grecaptchaLoaded&render=6LdI1rAUAAAAACI0GsFv-yRpC0tPF5ECiIMDUz2x" );
        injectScript( iframewin, "https://assets.propublica.org/prod/v3/js/recaptcha-init.js" );

        var $modalForms = $( iframewin.document.body ).children().first();
        handleForms( hostCookie, $modalForms );

        iframewin.document.close();
      }
    },
    handleForms = function( hostCookie, $modal ) {
      var hostedModal = window.parent.document.querySelector( '.syndicated-modal' );

      $modal
        .on( 'click', function( e ) {
          if( e.target === this ) {
            hostedModal.parentNode.removeChild( hostedModal );
          }
          e.stopPropagation();
        })
        .find( '.close-btn, .close-link' )
          .on( 'click', function( e ) {
            cookie( hostCookie + '_newsletter_interstitial_display', 'false', 365);
            hostedModal.parentNode.removeChild( hostedModal );
            e.preventDefault();
          });

      $modal
        .find( '.newsletter-ajax' )
        .on( 'submit', function( e ) {
          var $form = $( this ),
            $submit = $form.find( 'input[type="submit"]' ),
            url = $form.attr( 'action' ),
            data = $form.serialize();

          $submit
            .val( 'Submitting…' )
            .attr( 'disabled', 'true ')
            .addClass( 'submitting' );

          $.ajax({
            type: 'POST',
            url: url,
            data: data,
            success: function( res ) {
              $form
                .removeClass( 'submitting', 'error' )
                .addClass( 'success' );

              $submit.val( "You’re signed up!" );
              cookie( hostCookie + '_newsletter_bigstory_subscribed', 'true', 365);
              setTimeout(function() {
                hostedModal.parentNode.removeChild( hostedModal );
              }, 2000);
            },
            error: function( res ) {
              $form
                .removeClass( 'submitting' )
                .addClass( 'error' );

              $submit
                .val( 'Check email address and try again' )
                .removeAttr('disabled');
            }
          });
          e.preventDefault();
        });
    };

  $hostEl.each(function() {
    var $el = $( this ),
      endpoint = $el.data( 'endpoint' ) || defaults.endpoint;

    $.ajax({
      type: 'GET',
      url: endpoint,
      context: $( this )[ 0 ],
      success: handleResult
    });
  });

})(jQuery);